import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../components/layout'
import SEO from '../components/seo'
import BreadCrumb from '../components/breadCrumb'

const BlogPost = ({ data }) => {
  const { title, body, tag, publishDate } = data.contentfulBlogPost
  const options = {
    renderNode: {
      'embedded-asset-block': node => {
        const alt = node.data.target.fields.description['en-US']
        const url = node.data.target.fields.file['en-US'].url
        return <img alt={alt} src={url} />
      },
    },
  }

  const crumbs = [
    {
      depth: '1',
      url: '/',
      title: 'Home',
      styles: '',
    },
    {
      depth: '2',
      url: '/thoughts',
      title: 'Thoughts',
      styles: '',
    },
  ]


  return (
    <Layout>
      <SEO title={title} />
      <BreadCrumb crumbs={crumbs} />

      <h1 className="text-4xl font-bold">{title}</h1>
      {tag.map(tag => {
        //TODO: This is dirty as hell, gonna need a refactor
        if (tag === 'Wellbeing') {
          return (
            <div key={tag} className="flex flex-wrap mb-5">
              <div className="sm:w/1/6 lg:w-1/12 my-auto">
                <img className="p-1 justify-center" src="//images.ctfassets.net/y48msxixapg3/3URhnWFPLiC51zRVzWMhQ/6fbf46ce213f09ff9c7177df629a0969/Wellbeing_thought.png?w=50&q=50" alt={tag} />
              </div>
              <div className="sm:w-5/6 lg:w-5/6 md:mt-0 sm:pl-3 md:pl-0">
                <p className="text-gray-700 text-base text-xs mb-0">Category:&nbsp;<span className="italic">{tag}</span></p>
                <p className="font-bold mb-0">{publishDate}</p>
              </div>
            </div>
          )
        } else if (tag === 'Design') {
          return (
            <div key={tag} className="flex flex-wrap mb-5">
              <div className="sm:w/1/6 lg:w-1/12 my-auto">
                <img className="p-1 justify-center" src="//images.ctfassets.net/y48msxixapg3/5OqG0ehIS5QUGJRd55jVJ5/ac76d27f184521a065d9ae77ba3b089d/Design_thought.png?w=50&q=50" alt={tag} />
              </div>
              <div className="sm:w-5/6 lg:w-5/6 md:mt-0 sm:pl-3 md:pl-0">
                <p className="text-gray-700 text-base text-xs mb-0">Category:&nbsp;<span className="italic">{tag}</span></p>
                <p className="font-bold mb-0">{publishDate}</p>
              </div>
            </div>
          )
        } else if (tag === 'Research') {
          return (
            <div key={tag} className="flex flex-wrap mb-5">
              <div className="sm:w/1/6 lg:w-1/12 my-auto">
                <img className="p-1 justify-center" src="//images.ctfassets.net/y48msxixapg3/6empwIq22RWxWOQetFeGDp/5167582b56fb8ae5ee4e9a60c28232dd/Research_thought.png?w=50&q=50" alt={tag} />
              </div>
              <div className="sm:w-5/6 lg:w-5/6 md:mt-0 sm:pl-3 md:pl-0">
                <p className="text-gray-700 text-base text-xs mb-0">Category:&nbsp;<span className="italic">{tag}</span></p>
                <p className="font-bold mb-0">{publishDate}</p>
              </div>
            </div>
          )
        } else if (tag === 'Talks') {
          return (
            <div key={tag} className="flex flex-wrap mb-5">
              <div className="sm:w/1/6 lg:w-1/12 my-auto">
                <img className="p-1 justify-center" src="//images.ctfassets.net/y48msxixapg3/6ox7ZBXp975eTS0axT1Tmj/d1ca86ddd819276539374e9090cc976b/Talks_thought.png?w=50&q=50" alt={tag} />
              </div>
              <div className="sm:w-5/6 lg:w-5/6 md:mt-0 sm:pl-3 md:pl-0">
                <p className="text-gray-700 text-base text-xs mb-0">Category:&nbsp;<span className="italic">{tag}</span></p>
                <p className="font-bold mb-0">{publishDate}</p>
              </div>
            </div>
          )
        } else if (tag === 'Leadership') {
          return (
            <div key={tag} className="flex flex-wrap mb-5">
              <div className="sm:w/1/6 lg:w-1/12 my-auto">
                <img className="p-1 justify-center" src="//images.ctfassets.net/y48msxixapg3/6DpXbwsJjfXgvPXIP2VrZV/bfcc188d65e84f89b3687bfa5a75430e/Leadership_thought.png?w=50&q=50" alt={tag} />
              </div>
              <div className="sm:w-5/6 lg:w-5/6 md:mt-0 sm:pl-3 md:pl-0">
                <p className="text-gray-700 text-base text-xs mb-0">Category:&nbsp;<span className="italic">{tag}</span></p>
                <p className="font-bold mb-0">{publishDate}</p>
              </div>
            </div>
          )
        } else if (tag === 'UX') {
          return (
            <div key={tag} className="flex flex-wrap mb-5">
              <div className="sm:w/1/6 lg:w-1/12 my-auto">
                <img className="p-1 justify-center" src="//images.ctfassets.net/y48msxixapg3/2AYOE6ISm9SjgeDubdELcJ/75bc399905ac31f16561636a4fe406f6/UX_thought.png?w=50&q=50" alt={tag} />
              </div>
              <div className="sm:w-5/6 lg:w-5/6 md:mt-0 sm:pl-3 md:pl-0">
                <p className="text-gray-700 text-base text-xs mb-0">Category:&nbsp;<span className="italic">{tag}</span></p>
                <p className="font-bold mb-0">{publishDate}</p>
              </div>
            </div>
          )
        }
      })}
      <section id="thoughtpost">
        {documentToReactComponents(body.json, options)}
      </section>
    </Layout>
  )
}
export default BlogPost
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      tag
      publishDate (formatString: "MMMM DD, YYYY")
      body {
        json
      }
    }
  }
`
